// @ts-check
import Ready from "../lib/ready.js"
import * as focusTrap from 'focus-trap';

const nav = document.querySelector('.main-nav')

const navItems = document.querySelectorAll('.main-nav__list > li')
const hamburger = document.querySelector('.hamburger')
const menuTrap = focusTrap.createFocusTrap('.header__inner');
/**
 * @type { boolean }
 */
let navVisible = false

function init() {
  if (window.innerWidth < 800) {
    nav.style.transform = "translateX(100%)"
    nav.style.visibility = 'hidden';
    document.querySelector('.hamburger--close').style.opacity = 0
    document.querySelector('.hamburger--open').style.opacity = 1
    document.querySelector('.hamburger--open').style.transform = 'translateX(0)';
  } else {
    nav.style.transform = "translateX(0)"
    document.querySelector('.hamburger--open').style.visibility = 'hidden';
  }
}

function toggleNav() {
  navVisible = !navVisible
  if (navVisible) {
    menuTrap.activate()
    document.body.classList.add('nav-open')
    nav.style.transform = "translateX(0)"
    nav.style.visibility = 'visible';
    document.querySelector('.hamburger--open').style.opacity = 0
    document.querySelector('.hamburger--close').style.opacity = 1
  } else {
    menuTrap.deactivate()
    document.body.classList.remove('nav-open')
    nav.style.transform = "translateX(100%)"
    nav.style.visibility = 'hidden';
    document.querySelector('.hamburger--open').style.opacity = 1
    document.querySelector('.hamburger--close').style.opacity = 0
  }
}

Ready(() => {
  init()
  // Trap focus on the menu once it's revealed
  hamburger.addEventListener('click', toggleNav)
  window.addEventListener('resize', init)
})