// @ts-check

export default () => {
  window.mapboxgl.accessToken = 'pk.eyJ1Ijoic29kZXNpZ24iLCJhIjoiS0FYeU5LNCJ9._WhGoOkYPF4oGYvto2Ph8w'

  /**
   * @type {Object}
   */
  let features

  const map = new window.mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/sodesign/ckjn11tbb08r41ap303e4gqnj?v=2101220941',
    center: [-2.364, 51.378],
    zoom: 12
  });

  map.scrollZoom.disable();
  map.addControl(new window.mapboxgl.NavigationControl());

  map.on('load', function () {
    // add a sky layer that will show when the map is highly pitched
    map.addLayer({
    'id': 'sky',
    'type': 'sky',
    'paint': {
    'sky-type': 'atmosphere',
    'sky-atmosphere-sun': [0.0, 0.5],
    'sky-atmosphere-sun-intensity': 20
    }
    });
  });



  map.on('click', 'bath-stone-walking-tour', function (e) {
    map.flyTo({
      center: e.features[0].geometry.coordinates
    });
  });

  const popup = new mapboxgl.Popup(
    {
      offset: [0, -15],
      className: 'map-popup',
      closeButton: false,
      closeOnClick: false
    }
  )


  map.on('mouseenter', 'bath-stone-walking-tour', function(e) {
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['bath-stone-walking-tour']
    });


    if (!features.length) {
      return;
    }

    map.getCanvas().style.cursor = 'pointer';


    const feature = features[0];
    const featureId = feature.properties.id
    const featureListEl = document.querySelector(`.map-locations div[data-pin="${featureId}"`)
    /**
     *
     * @param {HTMLElement} featureListEl
     */
    const logoUrl = featureListEl.dataset.logo
    popup.setLngLat(feature.geometry.coordinates)
    .setHTML(
      `<img src="${logoUrl}" alt="${feature.properties.title}" />`
      )
    .addTo(map);
  });
  map.on('mouseleave', 'bath-stone-walking-tour', function(e) {
    map.getCanvas().style.cursor = '';
    popup.remove();
  })

  map.on('load', function() {
    features = map.queryRenderedFeatures({
      layers: ['bath-stone-walking-tour']
    });

    /**
     * @type {NodeListOf<HTMLElement>}
     */
    const locationButtons = document.querySelectorAll('.map-locations div')

    Array.from(locationButtons).forEach(
      /**
       *
       * @param {HTMLElement} b
       */
      (b) => {
        const pin = parseInt(b.dataset.pin)
        b.addEventListener('click', () => {
          centerPin(pin)
        })
        const description = b.querySelector('.description')
        const readMore = b.querySelector('.read-more')
        const readLess = b.querySelector('.read-less')
        if (readMore && readLess) {
          readMore.addEventListener('click', () => {
            description.classList.remove('excerpt')
          })
          readLess.addEventListener('click', () => {
            description.classList.add('excerpt')
          })
        }
      })
    })

  /**
   * Finds the map marker matching and flies to it
   *
   * @param {Number} pin
   */
  function centerPin(pin) {
    const marker = features.find(
      /**
       *
       * @param {Object} f
       */
      (f) => { return f.properties.id === pin }
    )
    map.flyTo({
      center: marker.geometry.coordinates,
      zoom: 17,
      pitch: 70
    })
  }



  }