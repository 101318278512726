import Map from './components/map.js'
import Nav from './components/nav.js'
import Images from './components/images.js'
import scrollSnapPolyfill from 'css-scroll-snap-polyfill'
import Ready from './lib/ready.js'

Ready(() => {
  scrollSnapPolyfill()
})



const mapEl = document.querySelector('#map')
if (mapEl) {
  Map()
}

Images()