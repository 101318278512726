// @ts-check
import { Luminous, LuminousGallery } from 'luminous-lightbox';
import Ready from "../lib/ready.js"
import ImagesLoaded from "imagesloaded"
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const images = document.querySelector('.page-images')
const content = document.querySelector('.content-editor-inner')

function imagesTallerThanContent() {
  const imagesHeight = images?.clientHeight
  const contentHeight = content?.clientHeight
  if (imagesHeight === 0 || contentHeight === 0) {
    return false
  }
  return imagesHeight > contentHeight
}

function scrollDifference() {
  const imagesHeight = images.clientHeight
  const contentHeight = content.clientHeight
  return contentHeight/imagesHeight * -100
}

function setUpParallax() {
  const parallaxRequired = imagesTallerThanContent()
  if (!parallaxRequired) {
    // Destroy parallax
    return
  }
  /**
   * @type { HTMLImageElement[] }
   */
  const imagesImgs = gsap.utils.toArray('.page-images__image');
  imagesImgs.forEach(/** @param { HTMLImageElement } img */ img => {
    gsap.to(img, {
      yPercent: scrollDifference() * 1.5,
      ease: "none",
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: content,
        start: "0% 30%",
        end: "100% 20%",
        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      }
    });
  });
}

export default () => {
  Ready(() => {
      new LuminousGallery(document.querySelectorAll(".lightbox"), {}, {sourceAttribute: 'data-href'});
      ImagesLoaded(".page-images img", setUpParallax)
  })
}